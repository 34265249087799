<template>
  <div>
    <SubHeaderBlock />
    <!--      <FilterBlock />-->
    <div class="analytics__table container">
      <component :is="block" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    SubHeaderBlock: () => ({
      component: import("./components/subHeader"),
    }),
    // FilterBlock: () => ({
    //     component: import('@/components/filter/filter')
    // }),
    AccountsBlock: () => ({
      component: import("./components/accounts"),
    }),
    ProductsBlock: () => ({
      component: import("./components/products"),
    }),
    CostsBlock: () => ({
      component: import("./components/costs"),
    }),
    LogisticsBlock: () => ({
      component: import("./components/logistics"),
    }),
  },
  computed: {
    block() {
      if (!this.$route.params.type) {
        return "products-block";
      }
      return this.$route.params.type + "-block";
    },
  },
};
</script>

<style scoped></style>
